'use client';

import React, { useState } from 'react';
export const PasswordPromptDialog = () => {
  const [password, setPassword] = useState('');
  const [passwordIncorrect, setPasswordIncorrect] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    const request = await fetch(`/api`, {
      body: JSON.stringify({
        password
      }),
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'post'
    });
    if (request.status !== 200) {
      setPasswordIncorrect(true);
      setLoading(false);
      return;
    } else {
      window.location.reload();
    }
  };
  return <div className="password-prompt-dialog" data-sentry-component="PasswordPromptDialog" data-sentry-source-file="PasswordPromptDialog.tsx">
      <form onSubmit={handleSubmit}>
        <label htmlFor="password">Password:</label>
        <input type="password" id="password" value={password} onChange={e => setPassword(e.target.value)} />
        <button type="submit">Submit</button>
      </form>
    </div>;
};