import(/* webpackMode: "eager", webpackExports: ["NextSSRPlugin"] */ "/vercel/path0/node_modules/@uploadthing/react/next-ssr-plugin/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"src/components/shared/Root/Root.tsx\",\"import\":\"\",\"arguments\":[{\"display\":\"swap\",\"variable\":\"--font-reddit-sans\",\"fallback\":[\"system-ui\",\"sans-serif\"],\"src\":[{\"path\":\"../../../../assets/fonts/RedditSans-Variable.ttf\"},{\"path\":\"../../../../assets/fonts/RedditSans-Italic-Variable.ttf\",\"style\":\"italic\"}]}],\"variableName\":\"reddit\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/material-symbols/index.css");
;
import(/* webpackMode: "eager", webpackExports: ["PasswordPromptDialog"] */ "/vercel/path0/src/components/shared/PasswordPromptDialog/PasswordPromptDialog.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/globals.scss");
